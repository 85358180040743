import React, {useState, useEffect} from 'react';
import TitleBlock from "../../../../components/TitleBlock"
import Avatar from '@material-ui/core/Avatar';
import ContactForm from "../../../../components/ApplyForm/ContactForm";
import SuccessPage from "../../../../components/ApplyForm/SuccessPage";
import manager from "../../../../images/team_sasha.jpg";
import {navigate} from "gatsby";
import "./style.styl";



export default function RequestSection(props) {
    const [submitted, setSubmitted] = useState(false)

    useEffect(() => {
        if (submitted) {
            setTimeout(() => navigate("/"), 10000)
        }
    }, [submitted])

    if (submitted) {
        return <SuccessPage show={submitted} onMainPage={() => setSubmitted(false)}/>
    }

    return (
        <section id="buy-section" className="">
            <div className="container">
                <div className="left u-padding25 u-xs-padding0 buy-form">
                    <TitleBlock {...props}
                                title={<span>Request a Proposal</span>}
                                description={props.isMobile ? "Let's work together" : ""}
                                />
                            <div className="u-marginTop5">
                        <ContactForm {...props} onSubmit={() => {setSubmitted(true);}}/>
                    </div>
                </div>
                <div className="right u-flexColumn u-justifyContentCenter u-paddingHorizontal24">
                    <div className="u-xs-hide">
                        <TitleBlock {...props}
                                    title={<span>Let's work together</span>}
                                    description={<span className="u-paddingTop15">Your Request is being processed by</span>}/>
                    </div>
                    <div className="u-sizeFullWidth u-padding25 u-flexColumn u-alignItemsCenter u-justifyContentCenter">
                        {
                            props.isMobile ?
                                <div className="u-opacity50 u-textAlignCenter u-maxWidth200">
                                    Your Request is being processed by
                                </div>
                                :
                                null
                        }
                        <div>
                            <Avatar className="u-width100 u-height100 u-marginVertical10" alt="Pavel" src={manager} />
                        </div>

                            <TitleBlock
                                        className="manager-name"
                                        isAlignCenter
                                        title="Pavel"
                                        description="Project Manager"/>
                    </div>
                </div>
            </div>

        </section>
    );
}
