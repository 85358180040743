import React, {useEffect, useState} from "react";
import NextButton from "./NextButton";
import {useCarousel} from "../../../Carousel"
import Bullets from "./Bullets";
import Slide1 from "./Slides/Slide1";
import "./style.styl";
import "./Slides/style.styl";


function initSlides(unique) {
    let slides = [
        {component: <Slide1/>, theme: "carousel-dark"}
    ];
    return slides
}

function AddExtraProps(Component, extraProps) {
    return <Component.type {...Component.props} {...extraProps} />;
}

export default function CarouselSection(props) {
    const {unique, animatedInitial, isMobile, location, store_settings, mediaPath, dispatch} = props;

    const slides = initSlides(unique)

    const length = slides.length;

    const [slideNumber, setSlideNumber, handlers, style] = useCarousel(length, 10000);
    const [animate50, setAnimate50] = useState(false);
    const [loading, setLoading] = useState(true);
    const slide = slides[slideNumber];

    const animatedInitialClass = loading ? "" : "show";

    useEffect(() => {
        setAnimate50(false)
        const id = setTimeout(() => setAnimate50(true), 50);
        return () => clearTimeout(id);
    }, [slideNumber]);

    useEffect(() => {
        const id = setTimeout(() => {setLoading(false)}, 100);
        return () => clearTimeout(id);
    }, []);

    return (
        <div id="carousel-section" className="">
            <div className={"carousel-widget u-overflowHidden u-relative u-borderRadius3 " + (slide.theme || "")} style={{paddingBottom: 80, height: props.height}}>
                <div className="u-absolute u-flexCenter" {...handlers} style={{...style, height: "calc(100% - 80px)"}}>
                    <div className={"slide flexCenter selected"}>
                        {AddExtraProps(slides[length - 1].component, {animatedInitialClass, location, isMobile, dispatch, mediaPath, store_settings})}
                    </div>
                    {
                        slides.map((v, i) => {
                            const selectedClass = (i === slideNumber) ? " selected" : ""
                            return (
                                <div key={i} className={"slide flexCenter selected"}>
                                    {AddExtraProps(v.component, {animatedInitialClass, location, isMobile, dispatch, mediaPath, store_settings})}
                                </div>
                            )
                        })
                    }
                    <div className={"slide flexCenter selected"}>
                        {AddExtraProps(slides[0].component, {animatedInitialClass, location, isMobile, dispatch, mediaPath, store_settings})}
                    </div>
                </div>

                {
                    length > 1 ?
                        <NextButton isActive={animate50} onClick={() => setSlideNumber((slideNumber + 1) % length)}/>
                        :
                        null
                }
                {
                    length > 1 ?
                        <Bullets selected={slideNumber} onSelect={setSlideNumber} items={slides.map((v, i) => ({value: i}))}/>
                        :
                        null
                }
                {
                    loading ?
                        <div className="u-absolute u-sizeFull u-zIndex3 u-backgroundWhite u-flexCenter u-justifyContentCenter">
                            {/*<Loader/>*/}
                        </div>
                        :
                        null
                }
            </div>
        </div>
    )
}
