import React from "react";
import "./style.styl";


export default class MouseIcon extends React.Component{

    render(){
        const {animatedClass} = this.props;
        return (
            <div className={`container-ms ${animatedClass}`}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 32">
                    <path
                        d="M11.5 31.75c6.1 0 11.1-5.05 11.1-11.25v-9C22.6 5.3 17.65.25 11.5.25 5.35.25.4 5.3.4 11.5v9c0 6.2 5 11.25 11.1 11.25zM2.4 11.5c0-5.1 4.05-9.25 9.1-9.25s9.1 4.15 9.1 9.25v9c0 5.1-4.05 9.25-9.1 9.25S2.4 25.6 2.4 20.5v-9z"></path>
                    <path
                        d="M11.5 14.55c.55 0 1-.45 1-1V9.9c0-.55-.45-1-1-1s-1 .45-1 1v3.65c0 .55.45 1 1 1z"></path>
                </svg>
            </div>
        )
    }
}