import React from 'react';
import TitleBlock from "../../../../components/TitleBlock";
import ItemFunc from "./Item";
import ItemGallery from "../../../../components/ItemGallery";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { Link } from "gatsby"
import {services} from "../../../../components/Header";
import "./style.styl";

const colors = ["#ffeedf", "#e4f0fb", "#ebecee"]
const subcolors = ["#fffcf8", "#fafeff", "#f9f9f9"]


export default function ServiceSection(props) {
    const {isMobile, onBuy} = props;
    return (
        <section id="service-section" className="">
            <div className="main-title">
                <TitleBlock {...props}
                            isAlignCenter
                            title={<div>Our Services</div>}
                            />
            </div>
            <div className="container u-flexColumn u-alignItemsCenter">
                {
                    services.map((service, i) => {
                        const color = colors[i]
                        const subcolor = subcolors[i]
                        return (
                            <div key={i} className="u-sizeFullWidth u-marginBottom50 u-xs-marginBottom50 u-flexCenter u-justifyContentSpaceAround u-xs-flexColumn">
                                <div className="u-flexCenter u-justifyContentCenter u-relative">
                                    <div className="service-box u-sizeFullWidth u-borderRadius10 u-paddingHorizontal24 u-paddingVertical20 u-flexColumn" style={{backgroundColor: color}}>
                                        <span className="title u-flexCenter">
                                            <span className="u-marginRight10 u-flexCenter">{service.icon}</span>{service.name}
                                        </span>
                                        {
                                            service.description ?
                                                <span className="description u-marginTop10">
                                                    {service.description}
                                                </span>
                                                :
                                                null
                                        }

                                        <span className="u-marginTop10 u-flexCenter u-justifyContentEnd">
                                            <Link to={service.link}>
                                                <span className="button-blue u-flexCenter">
                                                    Learn More <KeyboardArrowRightIcon className="u-marginRightNegative5" fontSize="small"/>
                                                </span>
                                            </Link>
                                        </span>
                                    </div>

                                    <div className="u-absolute u-opacity80" style={{right: -75}}>
                                        <DoubleArrowIcon className="u-textColorNormal" fontSize="large"/>
                                    </div>
                                </div>

                                <div className="u-sizeFullWidth body u-marginTop0 u-xs-marginTop10 u-maxWidth300 u-xs-maxWidthNone">
                                    <ItemGallery {...props} items={service.children || []} itemComponent={ItemFunc} color={subcolor}/>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </section>
    );
}
