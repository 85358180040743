import React, {useState, useEffect} from 'react';
import ServiceSection from "./ServiceSection"
import CarouselSection from "./CarouselSection";
import ForWhomSection from "./ForWhomSection";
import RequestSection from "./RequestSection";
import {addParamToQueryString} from "../../utils";
import "./style.styl";



export default function RocketPage(props) {
    const {location, isScroll100, isMobile, dispatch} = props;
    const [buyDialogOpen, setBuyDialogOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [theme, setTheme] = useState("light");


    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 500)
    }, [])

    const onBuy = () => setBuyDialogOpen(true)

    return (
        <div className="rocket-page beauty-landing-page u-relative">
            <CarouselSection {...props} height={isMobile ? "650px" : "600px"}/>
            <ServiceSection {...props} onAction={() => dispatch(addParamToQueryString("show_promo", "true", location))}/>
            <ForWhomSection {...props}/>
            <RequestSection {...props}/>
        </div>
    );
}
