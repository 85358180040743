import React from 'react';
import TitleBlock from "../../../TitleBlock"
import ItemListBlock from "../../../ItemListBlock"
import Whatshot from '@material-ui/icons/Whatshot';
import DeviceHub from '@material-ui/icons/DeviceHub';
import Loyalty from '@material-ui/icons/Loyalty';
import team from "../../../../images/team.png"
import "./style.styl";

const items = [
    {
        title: "E-commerce Businesses",
        description: "We have great expertise in developing and promoting E-commerce in a result-driven way",
        preview: <Loyalty/>,
        props: {isAlignCenter: false, isPreviewLeft: true}
    },
    {
        title: "B2B Companies",
        description: "We have special marketing services for B2B businesses, developed by our in-house team",
        preview: <Whatshot/>,
        props: {isAlignCenter: false, isPreviewLeft: true}
    },
    {
        title: "Affiliate Networks",
        description: "We have our own Media Buying team and work with a number of affiliate networks",
        preview: <DeviceHub/>,
        props: {isAlignCenter: false, isPreviewLeft: true}
    }
]


export default function ForWhomSection(props) {

    return (
        <section id="for-whom-section" className="">
            <div className="container">

                <div className="left">
                    <img src={team} alt="Our Clients"/>
                </div>
                <div className="right">
                    <TitleBlock {...props}
                                className="main-title"
                                title="Our Clients"/>
                    <ItemListBlock  {...props}
                                    items={items}
                                    isVertical
                                    columns={3}/>
                </div>
            </div>
        </section>
    );
}
