import React from 'react';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Link } from "gatsby"
import "./style.styl";



export default function ItemFunc(options) {
    const {key, item, props} = options;

    const width = props.isMobile ? 280 : "100%"
    const points = item.points || []
    return (
        <div key={key} className={"tmallItem-container" + (props.isMobile ? "" : " u-paddingHorizontal20 u-paddingVertical10")}
             style={{width: width, minWidth: width}}>
            <div className="">
                <Link to={item.link}>
                    <div className={" service-box sub u-paddingHorizontal10 u-paddingVertical5 u-xs-paddingVertical15 1 u-relative" + (item.className || "")} style={{backgroundColor: props.color}}>
                        <div className="u-flexCenter u-justifyContentCenter">
                            <div className="u-flexCenter">
                                <div className="title">
                                    {item.name}
                                </div>
                                <KeyboardArrowRightIcon fontSize="small" className="u-marginRightNegative5"/>
                            </div>

                        </div>
                    </div>
                </Link>
            </div>
        </div>
    )
}
