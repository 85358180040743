import React from "react";
import MouseIcon from "../../MouseIcon";
import {onOpenApplyForm} from "../../../../../../components/ApplyForm"
import vv from "../../../../../../images/vv.mp4"
import "./style.styl";


const items_2 = [
    {
        //preview: <img src={adwords}/>,
        title: "GOOGLE ADS",
        description: ""
    },
    {
        //preview: <img src={facebook}/>,
        title: "FACEBOOK ADS",
        description: ""
    },
    {
        //preview: <img src={rocket}/>,
    title: "IT DEVELOPMENT",
        description: ""
    },
    {
        //preview: <img src={tiktok}/>,
        title: "TIKTOK ADS",
        description: ""
    }
]



export default function Slide1(props) {
    const {location, isMobile, dispatch} = props;
    return (
        <div className={`slide1 general u-relative u-flex u-sizeFull u-borderRadius3 u-padding24 u-xs-padding15`}>

             <div className="u-sizeFullWidth u-flexCenter u-justifyContentSpaceBetween u-xs-flexColumn container">
                 <h1 className="container-text u-marginBottom50 u-xs-marginBottom20 u-sizeFullWidth u-flexColumn u-alignItemsCenter">
                     <p className="pre-text u-xs-hide">Web & Marketing Agency</p>
                     <p className="main-text u-marginTop10 u-marginBottom5">LEADS</p>
                     <p className="main-text">
                         FOR YOUR BUSINESS
                     </p>
                     <p className="subtext u-paddingTop15 u-paddingBottom30 u-xs-paddingBottom20">Experienced team of Digital Marketers and Web Developers</p>
                     <span className={"button-dark-blue " + (isMobile ? "" : "large")} onClick={() => onOpenApplyForm("true", location)}>Lets Talk</span>
                 </h1>

                 <div className="u-sizeFullWidth container-video u-marginBottom50 u-xs-margin0">
                     <video className="u-sizeFull"  playsInline frameBorder="0" autoPlay loop muted>
                         <source src={vv} type="video/mp4"/>
                     </video>
                 </div>

                 {/*<div className="container-platform u-flexCenter u-justifyContentCenter">
                     <div className="u-flexCenter u-relative">
                         <div className="u-flexColumn u-marginRight5 u-sizeFullWidth u-zIndex2">
                             <img className="u-marginBottom10" src={platform1}/>
                             <img src={platform3}/>
                         </div>
                         <div className="u-flexColumn u-marginLeft5 u-sizeFullWidth u-zIndex2">
                             <img className="u-marginBottom10" src={platform2}/>
                             <img src={platform4}/>
                         </div>
                         <div className="container-bubble">
                             <Bubble/>
                         </div>
                     </div>
                 </div>
                 */}
             </div>


             <div className="u-xs-hide">
                 <MouseIcon/>
             </div>


        </div>
    )
}
