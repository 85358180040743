import React from 'react';
import TitleBlock from "../TitleBlock";
import "./style.styl";


export default function ItemListBlock(props) {
    const {items, itemComponent, isVertical, isMobile} = props;
    return (
        <div className={"item-list-block " + (isVertical ? "vertical" : "")}>
            {
                (items || []).map((item, i) => {

                    if (itemComponent) {
                        return itemComponent({item, props: {...props}})
                    }

                    return (
                        <div className="item-list-container" key={i}>
                            <div className={"item-list-item " + (item.className || "")}>
                                <TitleBlock {...(item.props || {})}
                                            title={item.title}
                                            preview={item.preview}
                                            description={item.description}/>
                            </div>
                        </div>
                    )

                    return (
                        <div className="item-list-container" key={i}>
                            <div className={"item " + (item.className || "")}>
                                {
                                    item.icon ?
                                        <div className="item-list-icon">
                                            {
                                                typeof item.icon === "string" ?
                                                    <div>{item.icon}</div>
                                                    :
                                                    item.icon
                                            }
                                        </div>
                                        :
                                        null
                                }

                                <div className="item-list-content">
                                    {
                                        item.title ?
                                            <h3 className="item-list-title">
                                                {item.title}
                                            </h3>
                                            :
                                            null
                                    }
                                    {
                                        item.description ?
                                            <p className="item-list-description">
                                                {item.description}
                                            </p>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
}
